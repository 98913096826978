import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; // Your main chatbot component

class ChatbotWidget extends HTMLElement {
  async connectedCallback() {
    const shadowRoot = this.attachShadow({ mode: 'open' });
    const div = document.createElement('div');
    shadowRoot.appendChild(div);

    // Use base URL from the attribute
    const scriptUrl = this.getAttribute('data-script-url');
    if (!scriptUrl) {
      console.error('Script URL is not provided');
      return;
    }

    const baseUrl = new URL(scriptUrl).origin;

    try {
      // Fetch the manifest file
      // console.log(`Fetching manifest from ${baseUrl}/asset-manifest.json`);
      const response = await fetch(`${baseUrl}/asset-manifest.json`);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const manifest = await response.json();

      // Get the CSS file from the manifest
      const mainCss = manifest.files['main.css'];
      if (!mainCss) {
        throw new Error('CSS file not found in manifest');
      }

      const cssResponse = await fetch(`${baseUrl}${mainCss}`);
      if (!cssResponse.ok) {
        throw new Error(`Network response was not ok: ${cssResponse.statusText}`);
      }
      let css = await cssResponse.text();

      const style = document.createElement('style');
      style.textContent = css;
      shadowRoot.appendChild(style);

      // Inject main.js script
      // const mainJs = manifest.files['main.js'];
      // if (mainJs) {
      //   const mainScript = document.createElement('script');
      //   mainScript.src = `${baseUrl}${mainJs}`;
      //   mainScript.type = 'text/javascript';
      //   shadowRoot.appendChild(mainScript);
      // }

      // Pass the metadata attributes to the React app through custom properties
      const attributes = [
        'logo-png',
        'icon-png',
        'name',
        'apikey',
        'endpoint',
        'welcome-message'
      ];
      attributes.forEach(attr => {
        const value = this.getAttribute(`data-${attr}`) || '';
        div.setAttribute(`data-${attr}`, value);
        // console.log(`Setting attribute ${attr} to ${value}`);
      });

    } catch (error) {
      console.error('Error loading assets:', error);
    }

    ReactDOM.render(<App />, div);
  }

  disconnectedCallback() {
    ReactDOM.unmountComponentAtNode(this.shadowRoot.querySelector('div'));
  }
}

customElements.define('chatbot-widget', ChatbotWidget);